'use client';

import React, { createContext, useState, useEffect, useMemo } from 'react';
import { AuthEvent, AuthenticatedUser, IAuthService, UserChangedHandler } from '../../Services/IAuthService';
import { SessionAuthService } from '../../Services/SessionAuthService';

export type AuthContextValue = {
	user: AuthenticatedUser | null;
	updateUser: (newUser: AuthenticatedUser | null) => void;
	authService: IAuthService;
	isLoading: boolean;
	isInitialLoading: boolean;
};

export const AuthContext = createContext<AuthContextValue | null>(null);

export type AuthProviderProps = {
	user?: AuthenticatedUser;
	autoFetchUser?: boolean;
	authService: IAuthService | 'session';
	children?: React.ReactNode;
};

export default function AuthProvider(props: AuthProviderProps) {
	const { children, autoFetchUser } = props;
	const [user, setUser] = useState<AuthenticatedUser | null>(props.user ?? null);

	const [isLoading, setIsLoading] = useState(false); // Assume true if we're fetching the user
	const [isInitialLoading, setIsInitialLoading] = useState(autoFetchUser ? true : false); // Assume true if we're fetching the user

	const authService = useMemo(() => {
		if (props.authService === 'session') {
			return new SessionAuthService();
		} else {
			return props.authService;
		}
	}, [props.authService]);

	useEffect(() => {
		if (autoFetchUser) {
			authService.fetchMe();
		}

		const unsubscribeFetchingUser = authService.on(AuthEvent.FetchingUser, () => {
			setIsLoading(true);
		});

		const unsubscribeUserChanged = authService.on<UserChangedHandler>(
			AuthEvent.UserChanged,
			(user: AuthenticatedUser | null) => {
				setIsInitialLoading(false);
				setIsLoading(false);
				setUser(user);
			},
		);

		return () => {
			unsubscribeFetchingUser();
			unsubscribeUserChanged();
		};
	}, [authService, autoFetchUser]);

	const updateUser = async (newUser: AuthenticatedUser | null) => {
		setUser(newUser);
		if (newUser) {
			await authService.updateUser(newUser);
		}
	};

	const authContextValue: AuthContextValue = {
		user,
		updateUser,
		authService,
		isLoading,
		isInitialLoading,
	};

	return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}

export function useAuthProvider() {
	const context = React.useContext(AuthContext);
	if (!context) {
		throw new Error('useAuthProvider must be used within an AuthProvider');
	}
	return context;
}

export function useAuthService() {
	const { authService } = useAuthProvider();
	return authService;
}
