import type { BrandingType } from '@dr-pam/common-types/database';
import EventDispatcher from '../Utils/EventDispatcher';
import FetchUtils from '../Utils/FetchUtils';
import { AuthEvent, AuthenticatedUser, IAuthService } from './IAuthService';
import type { UserWithoutSensitiveData } from './Server/UserService';

export class SessionAuthService extends EventDispatcher implements IAuthService {
	async signOut(): Promise<void> {
		const request = FetchUtils.get('/api/auth/logout');

		await request.response;

		this.dispatch(AuthEvent.UserChanged, null);
		return Promise.resolve();
	}

	async signIn(email: string, password: string): Promise<AuthenticatedUser | null> {
		try {
			const request = FetchUtils.postJson<AuthenticatedUser>('/api/auth/login', {
				email,
				password,
			});

			const user = await request.response;

			this.dispatch(AuthEvent.UserChanged, user);
			return user;
		} catch (err) {
			this.dispatch(AuthEvent.UserChanged, null);
			return null;
		}
	}

	async requestPasswordReset(email: string, brandingType: BrandingType): Promise<void> {
		const request = FetchUtils.postJson('/api/auth/request-reset', {
			email,
			brandingType,
		});

		await request.response;
	}

	async completePasswordReset(email: string, token: string, password: string): Promise<void> {
		const request = FetchUtils.postJson('/api/auth/complete-reset', {
			email,
			token,
			password,
		});

		await request.response;
	}

	async fetchMe() {
		try {
			const request = FetchUtils.getJson<UserWithoutSensitiveData>('/api/user/me');
			const user = await request.response;

			this.dispatch(AuthEvent.UserChanged, user);
			return user;
		} catch (err) {
			this.dispatch(AuthEvent.UserChanged, null);
			return null;
		}
	}

	async updateUser(user: AuthenticatedUser): Promise<AuthenticatedUser> {
		const request = FetchUtils.postJson<AuthenticatedUser>('/api/user/me', user);
		const updatedUser = await request.response;

		this.dispatch(AuthEvent.UserChanged, updatedUser);
		return updatedUser;
	}
}
