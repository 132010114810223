// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from '@dr-pam/common-types/json';
import type { BrandingType, User } from '@dr-pam/common-types/database';
import { IEventDispatcher } from '../Utils/EventDispatcher';
import type { UserWithoutSensitiveData } from './Server/UserService';

export enum AuthEvent {
	UserChanged = 'UserChanged',
	FetchingUser = 'FetchingUser',
}

export type UserChangedHandler = (user: User | null) => void;

export type AuthenticatedUser = PrismaJson.SessionUser;

export interface IAuthService extends IEventDispatcher {
	signOut(): Promise<void>;

	signIn(email: string, password: string): Promise<AuthenticatedUser | null>;

	updateUser(user: AuthenticatedUser): void;

	fetchMe(): Promise<UserWithoutSensitiveData | null>;

	requestPasswordReset(email: string, brandingType: BrandingType): Promise<void>;

	completePasswordReset(email: string, token: string, password: string): Promise<void>;
}
