'use-client';

import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';

export type GtmRuntimeConfig = {
	gtmId?: string;
};

export type GoogleTagManagerProps = {
	gtmId: string;
};

export default function GoogleTagManager(props: GoogleTagManagerProps) {
	const { gtmId } = props;
	const isInitialized = useRef(false);

	useEffect(() => {
		if (isInitialized.current) {
			return;
		}

		TagManager.initialize({
			gtmId,
		});

		isInitialized.current = true;
	}, [gtmId]);

	return null;
}
