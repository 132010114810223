// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventHandlerCallback = (...args: any[]) => void;

export type IEventDispatcher = {
	on<THandler extends EventHandlerCallback>(event: string, handler: THandler): () => void;
	off<THandler extends EventHandlerCallback>(event: string, handler: THandler): void;
	dispatch(event: string, ...args: unknown[]): void;
};

export default class EventDispatcher implements IEventDispatcher {
	protected readonly _handlers: Map<string, EventHandlerCallback[]> = new Map<string, EventHandlerCallback[]>();

	public on<THandler extends EventHandlerCallback>(event: string, handler: THandler) {
		const handlers = this._handlers.get(event) ?? [];
		handlers.push(handler);
		this._handlers.set(event, handlers);

		return () => {
			this.off(event, handler);
		};
	}

	public off<THandler extends EventHandlerCallback>(event: string, handler: THandler) {
		const handlers = this._handlers.get(event) ?? [];
		const index = handlers.indexOf(handler);
		if (index > -1) {
			handlers.splice(index, 1);
		}
		this._handlers.set(event, handlers);
	}

	public dispatch(event: string, ...args: unknown[]) {
		const handlers = this._handlers.get(event) ?? [];
		for (const handler of handlers) {
			try {
				handler(...args);
			} catch (err) {
				console.error(`Error dispatching event ${event}`, err);
			}
		}
	}

	public dispose() {
		this._handlers.clear();
	}
}
